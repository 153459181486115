import React, { useState, useEffect, useCallback } from 'react';

function CountdownTimer({ apiUrl, parameter }) {
  const [expiryTime, setExpiryTime] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/${parameter}`);
      const data = await response.json();
      const { time_period_hrs, datestamp } = data;
      const expiryDate = new Date(datestamp);
      expiryDate.setHours(expiryDate.getHours() + time_period_hrs);
      setExpiryTime(expiryDate);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [apiUrl, parameter]);

  useEffect(() => {
    fetchData();
    const timerInterval = setInterval(fetchData, 60000); // Refresh data every minute
    return () => clearInterval(timerInterval); // Cleanup timer interval
  }, [fetchData]);

  // Calculate time left
  function calculateTimeLeft() {
    if (!expiryTime) return null;
    const now = new Date();
    const difference = expiryTime - now;
    if (difference <= 0) return { expired: true };

    // Convert milliseconds to days, hours, minutes
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    return { days, hours, minutes, expired: false };
  }

  const timeLeft = calculateTimeLeft();

  return (
    <div>
      {timeLeft && !timeLeft.expired ? (
        <p>Time left: {timeLeft.days} Days, {timeLeft.hours} Hours, {timeLeft.minutes} Minutes</p>
      ) : (
        <p>Expired</p>
      )}
    </div>
  );
}

export default CountdownTimer;
