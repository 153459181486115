

import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { FaCopy } from 'react-icons/fa';

import CountdownTimer from './CountdownTimer';


import { BrowserRouter as Router, Route,  useParams, Routes} from 'react-router-dom';

import { FaEnvelope, FaCode, FaCalendar } from 'react-icons/fa'; 

import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:uniqueNumber?" element={<MainComponent />} />
      </Routes>
    </Router>
  );
}

function MainComponent() {
  
  const { uniqueNumber } = useParams();
  const [inputValue, setInputValue] = useState(uniqueNumber || '');
  const [msg, setMsg] = useState('');

  useEffect(() => {
    setInputValue(uniqueNumber || '');
  }, [uniqueNumber]);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://sms-backend.netlify.app/.netlify/functions/api/getMsg'); // Replace '/your-api-endpoint' with your actual API endpoint
        const data = response.data;
        if (Array.isArray(data) && data.length > 0) {
          setMsg(data[0].message); // Assuming your API response has a 'message' field
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  const [dateValue, setdateValue] = useState('');
  const [apiResult, setApiResult] = useState('');
  const [last2Number, set2Digit] = useState('');
  const [code, setCode] =  useState('');
  

  const apikey= "f0538d1eb68882ed9dd197ecAA923968";

  

  useEffect(() => {
    // Example action when last2Number changes
    
    // You can perform any action here based on the change in last2Number
    //const corsProxyUrl = 'https://corsproxy.io/?';
    //const numberURL=`https://api.sms-activate.org/stubs/handler_api.php?api_key=${apikey}&action=getRentList`; 
    //const proxyUrl=corsProxyUrl+numberURL;
    const url= `https://sms-backend.netlify.app/.netlify/functions/api/getPhone/${inputValue}`
    axios.get(url)
    .then(response => {
      // Extract phone number from the response data
      const data = response.data;
      if (data && data.length > 0) {
         console.log(data[0].phone);
          set2Digit(data[0].phone); // Assuming the response is an array with one object
      } else {
          set2Digit(null); // Set phone to null if no data or empty array returned
      }
  })
  .catch(error => {
      console.error('Error fetching phone number:', error);
  });

  }, [inputValue]); // Dependency array
  
  
  
    const copyCodeToClipboard = () => {
      navigator.clipboard.writeText(code)
        .then(() => {
          alert('Code copied to clipboard!');
        })
        .catch((error) => {
          console.error('Failed to copy code: ', error);
        });
    };

  const handleButtonClick = async () => {

      //const url = `https://api.sms-activate.org/stubs/handler_api.php?api_key=${apikey}&action=getRentStatus&id=${inputValue}`;
      
      const apiUrl = `https://api.sms-activate.org/stubs/handler_api.php?api_key=${apikey}&action=getRentStatus&id=${inputValue}`;

      // Prepend the CORS proxy server URL to the API URL
      const corsProxyUrl = 'https://corsproxy.io/?';
      const proxiedApiUrl = corsProxyUrl + apiUrl;

      axios.get(proxiedApiUrl)
        .then(response => {
          // Handle response data
      
          setdateValue(JSON.stringify(response.data.values[0].date));
          setApiResult(JSON.stringify(response.data.values[0].text));
          const regex = /\b\d{6}\b/; // Regular expression to match 6-digit numbers
          try{
            const match = response.data.values[0].text.match(regex);
            
            setCode(match[0]);

          }catch(e){
                 console.error(e);
          }
          


        })
        .catch(error => {
          // Handle errors
          console.error('Error fetching data:', error);
        });
      

    




  };
 
  return (

    <div>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">Xstream sms service</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  
    <div className="container mt-3">
    <div className="row justify-content-center">
    <div className="col-md-6">
      <div className="text-left">
        <p><b>Announcements: </b></p>
        <p>{msg}</p>
        <p><b>Telegram:</b><a href="https://t.me/Xstream_sms" target="_blank" rel="noopener noreferrer"> @Xstream_sms</a> </p>
        <p><b>Whatsapp:</b><a href="https://wa.me/+14246463310" target="_blank" rel="noopener noreferrer">   +14246463310</a> </p>
        
      </div>
    </div>
  </div>
  
  
  
        <div className="row justify-content-center mt-4">
        <div className="col-md-6">
          <div className="text-center">
            <div style={{ marginBottom: '20px' }}>
              <label className="form-control" style={{ width: '100%', height: '40px', border: '1px solid transparent', padding: '5px' }}>
                <span style={{ fontWeight: 'bold' }}><p>The last two digits of the number <b># {last2Number ? last2Number : "Number not available"}</b></p></span>
              </label>
            </div>
            <div>
              <label className="form-control" style={{ marginBottom:'20px', width: '100%', height: '40px', border: '1px solid transparent', padding: '5px' }}>
                <span style={{ fontWeight: 'bold' }}><p>Number Expires in</p> </span>
                <CountdownTimer apiUrl="https://sms-backend.netlify.app/.netlify/functions/api/getTime/" parameter={inputValue} />
                
              </label>
            </div>
          </div>
        </div>
      </div>
      
      <div className="row justify-content-center mt-4">
        <div className="col-md-6">
          <div className="text-center">
          <div className="mb-3">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="form-control"
                placeholder="Enter ID"
              />
            </div>
        
        

        
            <div className="mb-3">
             <button onClick={handleButtonClick} className="btn btn-outline-primary btn-block" style={{backgroundColor: 'white', color: 'blue', borderColor: 'blue'}}>Get Message</button>
            </div>

          </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      {apiResult && (
        <label className="form-control mb-3" style={{ width: '100%', maxWidth: '600px', height: '40px', border: '1px solid transparent', padding: '5px', textAlign: 'left' }}>
          <FaEnvelope style={{ marginRight: '5px' }} /> {/* Icon */}
          <span style={{ fontWeight: 'bold' }}>SMS:</span> {apiResult}
        </label>
      )}
      {code && (
        <label className="form-control mb-3" style={{ width: '100%', maxWidth: '600px', height: '40px', border: '1px solid transparent', padding: '5px', textAlign: 'left' }}>
          <FaCode style={{ marginRight: '5px' }} /> {/* Icon */}
          <span style={{ fontWeight: 'bold' }}>Code:</span> {code}
          <span style={{ marginRight: '5px',marginLeft:'10px', cursor: 'pointer' }} onClick={copyCodeToClipboard} ><FaCopy /></span> {/* Icon */}
        </label>
      )}
      {dateValue && (
        <label className="form-control mb-3" style={{ width: '100%', maxWidth: '600px', height: '40px', border: '1px solid transparent', padding: '5px', textAlign: 'left' }}>
          <FaCalendar style={{ marginRight: '5px' }} /> {/* Icon */}
          <span style={{ fontWeight: 'bold' }}>Date:</span> {dateValue}
        </label>
      )}
    </div>

        </div>
      </div>
    </div>
  </div>
  

  );
}

export default App;
